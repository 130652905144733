import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Obligation from './components/Obligation';
import Home from './components/Home';
import ResetPassword from './components/ResetPassword';
import ResetPasswordSuccess from './components/ResetPasswordSuccess';

function App() {

  

  return (
    <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/obligation" element={<Obligation />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/resetPassword-success" element={<ResetPasswordSuccess />} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
