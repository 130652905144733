import React from 'react'

function Home() {
  return (
    <div className='home-page'>
        <img src='/images/logo.png' alt="Logo" />
    </div>
  )
}

export default Home