import React from 'react'

function ObligationDetails(props) {
    const { show, close, labtest, acceptObligation, rejectObligation } = props;

    const accept = () => {
        acceptObligation(labtest?.id);
    }
    const reject = () => {
        rejectObligation(labtest?.id);
    }
    
    if (!labtest) return null;

    return (
        <div className={show ? "modal-container show" : "modal-container"}>
            <div className='modal-content'>
                <img src='/images/close-icon.png' alt='Icon' onClick={close} />
                <h3>تفاصيل الفحص</h3>
                <div className='details'>
                    <label>الفحص</label>
                    <input disabled={true} value={labtest?.lab_test_name} />
                    <label>بعض التوصيات المقترحة</label>
                    <textarea disabled={true} value={labtest?.notes}>
                    </textarea>
                    <div className='actions'>
                        <button type='button' onClick={close}>إلغاء</button>
                        {labtest?.is_approval 
                            ? <button type='button' onClick={reject}>إلغاء الموافقة</button> 
                            : <button type='button' onClick={accept}>موافق</button>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ObligationDetails